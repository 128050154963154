import React, { useCallback } from 'react';
import { Game } from "../../../models/poker";
import { PlayerAction, PlayerActionOptions } from '../../../models/types';
import ChipAmount from "../ChipAmount";
import { Add, Remove } from '@mui/icons-material';
import './BettingControlB.css';
import * as R from 'ramda';
import { objectSumValues } from '../../../models/helpers';

interface Props {
  game: Game,
  act: (action: PlayerAction) => void,
  myStack: number,
  actions: PlayerActionOptions | null,
};

const BettingControlB: React.FunctionComponent<Props> = ({ game, act: act_, myStack, actions }) => {
  const pot = R.sum(game.pots.map(p => p.amount)) + objectSumValues(game.currentBets);
  const bb = game.settings.bigBlindAmount;

  const act = useCallback((action: PlayerAction) => {
    act_(action);
    setInputValue(0);
  }, [act_]);

  const defaultAmount = useCallback(() => {
    if (!actions) return 0;
    if (actions.check) return 0;
    // Assert.expect(actions.check).beTruthy('callAction should be defined');
    return actions.call!.amount;
  }, [actions]);

  const previousDefaultAmount = React.useRef<any>(null);


  const [inputValue, setInputValue] = React.useState(defaultAmount);
  const inputValueMin = !actions ? 0 : actions.check ? 0 : actions.call!.amount;
  const inputValueMax = !actions ? myStack : actions.raise ? actions.raise.maxAmount : myStack;

  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(() => {
    let didPreviousDefaultAmountChange = false;
    if (previousDefaultAmount.current !== defaultAmount()) {
      previousDefaultAmount.current = defaultAmount();
      didPreviousDefaultAmountChange = true;
    }
    const timeoutMs = didPreviousDefaultAmountChange ? 0 : 1200;

    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setInputValue(inputValue => R.clamp(inputValueMin, inputValueMax, isNaN(inputValue) ? defaultAmount() : inputValue));
    }, timeoutMs);
  }, [inputValueMin, inputValueMax, inputValue, defaultAmount]);
  const currentInputIsValid = inputValue >= inputValueMin && inputValue <= inputValueMax;

  const adjustChipSelection = (change: number) => {
    setInputValue(inputValue => R.clamp(inputValueMin, inputValueMax, inputValue + change));
  };

  let currentAction: 'call' | 'raise' | 'check' | null = null;
  let currentActionAmount: number | null = null;

  currentAction = inputValue !== inputValueMin ? 'raise' : inputValue === 0 ? 'check' : 'call';
  currentActionAmount = inputValue;

  if (!actions) currentAction = null;

  let currentActionProcessedForDisplay: string = currentAction ?? '(not your turn)';
  // If player wants to "raise" while they can check, use the word "bet" instead
  if (currentActionProcessedForDisplay === 'raise' && actions?.check)
    currentActionProcessedForDisplay = 'bet';
  if (!currentInputIsValid)
    currentActionProcessedForDisplay = '(invalid)';

  return (
    <div className="grid primary-game-display-grid-area2 gap-2">
      <div className="sub btn" onClick={() => adjustChipSelection(-bb)}><Remove /></div>
      <div className="input">
        <input type="text" inputMode="numeric" value={inputValue} onChange={(e) => setInputValue(Number(e.target.value))} />
      </div>
      <div className="add btn" onClick={() => adjustChipSelection(+bb)}><Add /></div>
      {/********** Action Button **********/}
      <div className={`action btn ${(!currentAction || !currentInputIsValid) && 'invalid-state'}`} onClick={() => {
        if (!currentAction) return;
        if (currentAction === 'check') act({ type: 'check' });
        if (currentAction === 'call') act({ type: 'call' });
        if (currentAction === 'raise') act({ type: 'raise', raisingAmount: currentActionAmount! });
      }}>
        {/* This this the verb - check, bet, raise, etc */}
        {currentActionProcessedForDisplay}
        {/* This is the suffix. The amount and weather it's all in */}
        {(currentAction && currentInputIsValid) && <>
          <span className="amount">{currentActionAmount ? ` ${currentActionAmount}` : ''}</span>
          <span>{currentActionAmount === inputValueMax ? ' (All-in)' : ''}</span>
        </>}
      </div>
      {/********** Buttons for quick adjustments **********/}
      <div className="col-span-4">{/* I am a separator~ */}</div>
      <div className="col-span-2 btn" onClick={() => adjustChipSelection(bb * -3)}>-3 bb</div>
      <div className="col-span-2 btn" onClick={() => adjustChipSelection(bb * 3)}>+3 bb</div>
      <div className="col-span-1 btn" onClick={() => adjustChipSelection(Math.floor(pot / 4))}>+¼</div>
      <div className="col-span-1 btn" onClick={() => adjustChipSelection(Math.floor(pot / 2))}>+½</div>
      <div className="col-span-2 btn" onClick={() => adjustChipSelection(pot)}>+1 pot</div>
      {inputValue === defaultAmount() && currentAction ?
        <div className="col-span-2 btn red" onClick={() => act({ type: 'fold' })}>fold</div>
        :
        <div className="col-span-2 btn" onClick={() => adjustChipSelection(-999999)}>clear</div>
      }
      <div className="col-span-2 btn" onClick={() => adjustChipSelection(9999999)}>max</div>

    </div>

  );
};

export default BettingControlB;
