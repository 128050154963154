import React from 'react'

import { Table } from '../../models/poker'
import { PlayerAction, TableId } from '../../models/types'

import PrimaryGameDisplay from './PrimaryGameDisplay'
import JoinGameDisplay from './JoinGameDisplay'
import SetupGameDisplay from './SetupGameDisplay'
import { ServerProxyFirebase, ServerProxyLocal, ServerProxyInterface } from '../../models/server'

interface Props {
  playerId?: string;
  tableId: TableId;
  onTableDNE: () => void;
  useServer: 'remote' | 'local';
}

interface State {
  connecting: boolean;
  playerId: string;
  isConnected: boolean;
  table: Table | null;
}

function getPlayerIdFromURL() {
  return new URLSearchParams(window.location.search).get('player');
}

export default class ClientController extends React.Component<Props, State> {
  state: State = {
    connecting: false,
    playerId: this.props.playerId ?? getPlayerIdFromURL() ?? '',
    isConnected: false,
    table: null,
  }

  proxy!: ServerProxyInterface;
  tryConnectInterval: NodeJS.Timeout | null = null;

  componentDidMount(): void {
    if (!this.proxy) {
      if (this.props.useServer === 'remote') {
        this.proxy = new ServerProxyFirebase();
      } else {
        this.proxy = new ServerProxyLocal();
      }
      this.proxy.registerHandlers({
        onConnect: this.onServerConnected,
        onDisconnect: this.onServerDisconnected,
        onUpdate: this.onServerUpdate,
        onTableDNE: this.onServerTableDNE,
      });
      this.connect();
    }
  }

  connect = () => {
    this.setState({ connecting: true });
    this.proxy.joinTable(this.props.tableId);
    document.title = `Connecting to table ${this.props.tableId} | vchips.app`
  }

  onServerConnected = (table: Table) => {
    if (this.tryConnectInterval) clearTimeout(this.tryConnectInterval);
    this.setState({ table, connecting: false, isConnected: true });
    if (this.state.playerId !== '' && !table.players.includes(this.state.playerId)) {
      table.addNewPlayer(this.state.playerId);
      this.proxy.updateTable(table);
    }
    document.title = `${this.state.playerId} @ ${this.props.tableId} | vchips.app`
  }

  onServerDisconnected = () => {
    this.setState({ isConnected: false, connecting: false });
    document.title = `DISCONNECTED from table ${this.props.tableId} | vchips.app`
    if (this.tryConnectInterval) clearTimeout(this.tryConnectInterval);
    this.tryConnectInterval = setTimeout(() => {
      this.proxy.exitTable();
      this.connect();
    }, 2000);
  }

  onServerUpdate = (table: Table) => {
    this.setState({ table, isConnected: true, connecting: false });
  }

  onServerTableDNE = () => {
    this.setState({ connecting: false });
    this.props.onTableDNE();
  }

  disconnect = () => {
    this.proxy.exitTable();
    this.setState({ isConnected: false, table: null, connecting: false });
  }

  onAction = (action: PlayerAction) => {
    if (!this.state.table?.currentGame)
      throw new Error('Unexpected undefined game');
    this.state.table.currentGame.act(action);
    this.proxy.updateTable(this.state.table);
  }

  onTogglePot = (player: string, potIndex: number) => {
    if (!this.state.table?.currentGame)
      throw new Error('Unexpected undefined game');
    const g = this.state.table.currentGame;
    if (!g.potResult || !g.potResult[potIndex]) {
      throw new Error(`Pot ${potIndex} does not exist`);
    }
    if (g.potResult[potIndex].includes(player)) {
      g.potResult[potIndex] = g.potResult[potIndex].filter(p => p !== player);
    }
    else {
      g.potResult[potIndex].push(player);
    }
    this.proxy.updateTable(this.state.table);
  }

  render() {
    const { table } = this.state;
    const game = table?.currentGame ?? null;
    // @ts-ignore
    window.vchips = {
      game: game,
      table: table,
      proxy: this.proxy,
    }

    return (
      <>
        {
          table === null ?
            <JoinGameDisplay
              connecting={this.state.connecting}
              reconnect={() => { this.connect() }}
            />
            :
            !game ?
              <SetupGameDisplay
                isDisconnected={!this.state.isConnected}
                disconnect={() => this.disconnect()}
                table={table}
                tableId={this.props.tableId}
                playerId={this.state.playerId}
                setPlayerId={(playerId) => {
                  this.setState({ playerId });
                  if (playerId !== '') {
                    this.state.table!.addNewPlayer(playerId);
                    this.proxy.updateTable(this.state.table!);
                  }

                  if (!this.props.playerId) {
                    // Only add to the URL if we are not from the debug screen
                    const newURL = new URLSearchParams(window.location.search);
                    newURL.append('player', playerId);
                    window.location.search = newURL.toString();
                  }
                }}
                onUpdate={(newTable: Table) => this.proxy.updateTable(newTable)}
              />
              :
              <PrimaryGameDisplay
                game={game}
                isDisconnected={!this.state.isConnected}
                playerId={this.state.playerId}
                disconnect={() => this.disconnect()}
                act={(a: PlayerAction) => this.onAction(a)}
                onTogglePot={(player: string, potIndex: number) => this.onTogglePot(player, potIndex)}
                // newStackAmount={!game.isAllPotsSettled ? -1 : (game.getWinnerChipMap()[playerId] ?? 0) + game.gameStacks[playerId]}
                onGotoSetupScreen={() => { table.addCurrentGameToPreviousGames(); this.proxy.updateTable(table); }}
                onStartNewGame={() => { table.addCurrentGameToPreviousGames(); table.currentGame = table.initializeNewGame(); this.proxy.updateTable(table); }}
              />
        }
      </>
    )
  }
}


