import { FC } from "react";
import { Clear, Groups, AddCircleOutline } from '@mui/icons-material';
import { ServerProxyFirebase } from "../models/server";

const HomePage: FC = () => {
  const banner = window.location.hash;

  return (
    <div className="m-3 flex flex-col">
      {
        banner === '#table-dne' &&
        <div className="border border-red-400 bg-red-50 dark:bg-red-900 rounded m-2 p-2 flex justify-between">
          The table doesn't exist or isn't accepting new players.
          <div onClick={() => window.location.replace('/')}>
            <Clear />
          </div>
        </div>
      }

      <div className="font-black text-5xl">
        <div className="py-5 self-center">
          <div className="underline">vchips.app/</div>
          <div className="m-5">virtual chips for your real playing cards.</div>
        </div>
      </div>

      <div className="button2" onClick={async () => {
        const tableId = await new ServerProxyFirebase().createTable();
        window.location.replace('/' + tableId);
      }}>
        <div>Create Table</div>
        <AddCircleOutline />
      </div>

      <div className="button2" onClick={() => {
        const response = prompt('Table ID?');
        if (response)
          window.location.replace('/' + response);
      }}>
        <div>Join Table</div>
        <Groups />
      </div>
    </div>
  )
}

export default HomePage;
