import React from 'react';
import { Game } from "../../../models/poker";
import { PlayerAction, PlayerActionOptions } from '../../../models/types';
import ChipAmount from "../ChipAmount";
import './BettingControlA.css';

interface Props {
  game: Game,
  act: (action: PlayerAction) => void,
  myStack: number,
  actions: PlayerActionOptions | null,
};

const BettingControlA: React.FunctionComponent<Props> = ({ game, act, myStack, actions }) => {

  const [raisingAmount, setRaisingAmount] = React.useState(0);
  React.useEffect(() => {
    setRaisingAmount(actions?.raise?.minAmount || 0);
  }, [actions?.raise?.minAmount]);

  return (
    <div className="grid primary-game-display-grid-area gap-2">
      <div className="col-span-4">
        <input type="range" name="" id="raise-range-input" min={actions?.raise?.minAmount || 0} max={actions?.raise?.maxAmount || myStack} value={raisingAmount} onChange={(e) => { setRaisingAmount(Number(e.target.value)) }} />
      </div>
      <div className={`col-span-4 btn ${actions?.raise || 'disabled'}`} onClick={() => { if (actions?.raise) act({ type: 'raise', raisingAmount }) }}>Raise {raisingAmount}{raisingAmount === actions?.raise?.maxAmount ? ' (all-in)' : ''}</div>
      <div className={`col-span-4 btn ${actions?.call || 'disabled'}`} onClick={() => { if (actions?.call) act({ type: 'call' }) }}>Call {actions?.call?.amount || ''}{actions?.call?.isAllIn ? ' (all-in)' : ''}</div>
      <div className={`col-span-4 btn ${actions?.check || 'disabled'}`} onClick={() => { if (actions?.check) act({ type: 'check' }) }}>Check</div>
      <div className={`col-span-4 btn ${actions?.fold || 'disabled'}`} onClick={() => { if (actions?.fold) act({ type: 'fold' }) }}>Fold</div>
    </div>

  );
};

export default BettingControlA;
