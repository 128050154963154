import * as R from 'ramda';
import React, { FC } from 'react'
import type { PlayerAction, GameRoundName, PlayerActionOptions } from '../../models/types'
import { ChipAmount } from '../components';
import { Refresh, ArrowRight, SignalCellularConnectedNoInternet0Bar, FontDownload, Minimize } from '@mui/icons-material';
import { Game } from '../../models/poker';
import { findPlayer, objectSumValues } from '../../models/helpers';
import BettingControlA from '../components/BettingControls/BettingControlA';
import BettingControlB from '../components/BettingControls/BettingControlB';

interface Props {
  game: Game;
  isDisconnected: boolean;
  playerId: string;
  disconnect: () => void;
  act: (action: PlayerAction) => void;
  onTogglePot: (player: string, potIndex: number) => void;
  onGotoSetupScreen: () => void;
  onStartNewGame: () => void;
}



export default function PrimaryGameDisplay(props: Props) {
  const { game, isDisconnected, playerId, act, onTogglePot, onStartNewGame, onGotoSetupScreen } = props;
  const isMyTurn = game.currentPlayer === playerId;
  const state = extractDisplayStateFromGame(game, playerId);
  const potSizes = game.pots.map(p => p.amount);
  const myStack = game.gameStacks[playerId];
  const potCurrentRound = objectSumValues(game.currentBets);
  const actions = game.currentPlayer === playerId ? game.currentPlayerActionOptions : null;
  const currentRound = game.currentRound;
  const [bettingControlSwitch, setBettingControlSwitch] = React.useState(0);

  const promptText = (() => {
    if (game.currentRound === 'showdown') {
      if (game.isAllPotsSettled) {
        return <>All pots settled</>;
      } else {
        return <>Select winner to continue</>;
      }
    }

    return <>{actionOptionsToText(game.currentPlayer!, isMyTurn, game.currentPlayerActionOptions)}</>;
  })();

  return (
    <div className={`primary-game-display p-5 state-bg-${state} pgd-${state} ${isMyTurn && 'pgd-my-turn'} ${isDisconnected && 'pgd-disconnected'}`}>
      <div className="flex flex-col min-h-full">
        <div className="flex flex-col items-center">
          <PokerCardsContainer2 round={currentRound} />
        </div>

        <div className="text-center align-middle">
          {
            isDisconnected &&
            <div className="text-2xl text-red-500 p-2">
              <span className="animate-pulse pr-2"><SignalCellularConnectedNoInternet0Bar /></span>
              No Internet Connection
            </div>
          }
          <div className="text-lg">{promptText}</div>
        </div>

        <div className="MAIN-HORIZONTAL-FLEX flex flex-row">
          <div className="LEFT-COLUMN flex-[3]">
            <PlayerList game={game} me={playerId} isShowdown={game.currentRound === 'showdown'} onTogglePot={onTogglePot} />
          </div>

          <div className={"RIGHT-COLUMN flex-[4] pl-2 " + (game.currentRound === 'showdown' && 'hidden')}>
            <div className="CHIP-INFO mb-4">
              <table className="">
                <tbody>
                  <tr>
                    <td className="text-right pr-2">pot:</td>
                    <td className="text-left pl-2 text-3xl"><ChipAmount amount={R.sum(potSizes) + potCurrentRound} /></td>
                  </tr>
                  <tr>
                    <td className="text-right pr-2">my stack:</td>
                    <td className="text-left pl-2 text-3xl"><ChipAmount amount={myStack ?? -1} /></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex-1"></div>

            <div className="BET-CTRL button self-start opacity-5" onClick={() => setBettingControlSwitch(a => a + 1)}> Bet Ctrl <Refresh /></div>
            {((() => {
              const options = [
                <BettingControlB game={game} actions={actions} act={act} myStack={myStack} />,
                <BettingControlA game={game} actions={actions} act={act} myStack={myStack} />,
              ]

              return options[bettingControlSwitch % options.length]
            }))()}
          </div>
        </div>

        {
          game.currentRound === 'showdown' && game.isAllPotsSettled &&
          <div>
            <div onClick={() => onGotoSetupScreen()} className="button mt-4 debug-box cursor-pointer">
              Go to setup screen <ArrowRight />
            </div>
            <div onClick={() => onStartNewGame()} className="button mt-4 debug-box cursor-pointer">
              Next Round <ArrowRight />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

////////////////////////////////////////


function PlayerList({ game, me, isShowdown, onTogglePot }: { game: Game, me: string, isShowdown: boolean, onTogglePot: (player: string, potIndex: number) => void }) {
  const SDPH = isShowdown ? game.pots.map((_, i) => (<div key={i} />)) : null;
  const SDPH1 = isShowdown ? <div /> : null;

  const potCount = game.pots.length;

  const leftColumnWidth = (3 / 7 * 100) + '% ';
  const rightColumnWidth = (4 / 7 * 100 / potCount) + '% ';

  const css = leftColumnWidth + rightColumnWidth.repeat(potCount);

  return (
    <div className="PLAYER-LIST">
      <div className="ACTIVE grid gap-2" style={{ gridTemplateColumns: isShowdown ? css : '1fr' }}>
        {SDPH1}
        {
          isShowdown && game.pots.map((pot, potIndex) => (
            <div key={potIndex} className="text-center">{potIndex === 0 ? 'Pot' : 'Side Pot'}</div>
          ))
        }
        {
          [...game.allInPlayers, ...game.settledQueue, ...game.actQueue].map((player) => (
            <React.Fragment key={player} >
              <PlayerCard game={game} player={player} isMe={player === me} />
              {
                isShowdown && game.pots.map((pot, potIndex) => (
                  <WLSSelector
                    key={potIndex}
                    game={game}
                    player={player}
                    potIndex={potIndex}
                    onTogglePot={() => onTogglePot(player, potIndex)}
                  />
                ))
              }
            </React.Fragment>
          ))
        }

        <div className="mt-4"></div>{SDPH}

        {game.foldedPlayers.map((player) => (
          <React.Fragment key={player}>
            <div className="border-2 rounded-lg border-gray py-1 px-2 bg-gray-500 bg-opacity-30">
              <div className="flex">
                <div className="text-left opacity-50 line-through">
                  <span className="player">{player}</span>
                  <span>{me === player ? ' (me)' : ''}</span>
                </div>
                <div className="flex-1"></div>
                <div className="text-right">
                  {extractPosition(game, player)}
                </div>
              </div>
            </div>
            {SDPH}
          </React.Fragment>
        ))}

      </div>


      <div className="FOLDED mt-8">
      </div>
    </div>
  );
}

function WLSSelector({ game, player, potIndex, onTogglePot }: { game: Game, player: string, potIndex: number, onTogglePot: () => void }) {
  const potAmount = game.pots[potIndex].amount;
  const isWinner = game.potResult![potIndex].includes(player);
  const isInvolved = game.pots[potIndex].players.includes(player);

  const canToggle = game.pots[potIndex].players.length > 1 && isInvolved;
  let amountToDisplay = 0;
  let winnerSelected = true;
  if (game.potResult![potIndex].length === 0) {
    amountToDisplay = potAmount;
    winnerSelected = false;
  } else {
    if (isWinner)
      amountToDisplay = potAmount / game.potResult![potIndex].length; // TODO: Split into integer only
  }

  return (
    <div
      className={
        `${(winnerSelected && isWinner) ? 'text-green-600 dark:text-green-300' : 'opacity-60'} ` +
        `${canToggle ? 'cursor-pointer border rounded-lg' : 'cursor-not-allowed'} ` +
        `self-stretch flex items-center justify-center`
      }
      onClick={() => canToggle && onTogglePot()}
    >
      {
        isInvolved ?
          ((!winnerSelected || isWinner) ? <>+<ChipAmount amount={amountToDisplay} /></> : '––')
          :
          <span className="text-sm text-center">not involved</span>
      }
    </div>
  )
}

function PlayerCard({ game, player, isMe }: { game: Game, player: string, isMe: boolean }) {
  return (
    <div
      className={
        `border-2 rounded-lg border-gray py-1 px-2 ` +
        `state-bg-${extractDisplayStateFromGame(game, player)} ` +
        `${game.currentPlayer === player && 'player-list-current-player'}`
      }>
      <div className="flex">
        <span className="text-left">
          <span className="player">{player}</span>
          <span>{isMe ? ' (me)' : ''}</span>
        </span>
        <div className="flex-1"></div>
        <span className="text-right">
          {extractPosition(game, player)}
        </span>
      </div>
      <div className="flex">
        <div className="text-left">
          <ChipAmount amount={game.gameStacks[player] ?? -1} />
        </div>
        <div className="flex-1"></div>
        <div className="text-right">
          {extractDisplayStateFromGame(game, player).replace('default', '')}
        </div>
      </div>
    </div>

  );
}

const PokerCardsContainer2: FC<{ round: GameRoundName }> = ({ round }) => {
  const GameRoundNameToFaceUpCount: Record<GameRoundName, number> = {
    'pre-flop': 0,
    'flop': 3,
    'turn': 4,
    'river': 5,
    'showdown': 5,
  };

  const faceUpCount = GameRoundNameToFaceUpCount[round];

  return (
    <div className="flex flex-row items-center justify-center text-xs relative">
      <div className='opacity-50 absolute top-0 left-0 h-full w-full text-center text-sm'>
        {round.toLocaleUpperCase()}
      </div>
      <div className="opacity-10">
        {
          Array.from({ length: 5 }).map((_, i) =>
            i + 1 <= faceUpCount ? <FontDownload key={i} /> : <Minimize key={i} />
          )
        }
      </div>
    </div>
  );
}

/**
 *
 * @param playerId
 * @param isMe
 * @param actionOptions
 * @returns string
 *
 * It's Dennis's turn: 50 to call
 * It's Dennis's turn: all-in (50) to call
 * It's Dennis's turn
 * It's your turn: 50 to call
 * It's your turn: all-in (50) to call
 * It's your turn
 */
function actionOptionsToText(playerId: string, isMe: boolean, actionOptions: PlayerActionOptions) {
  let textPart1 = isMe ? 'your turn' : <><span className="player">{playerId}</span>'s turn</>;
  let textPart2 = '';
  if (actionOptions.call) {
    if (actionOptions.call.isAllIn) {
      textPart2 = `: all-in (${actionOptions.call.amount}) to call`;
    } else {
      textPart2 = `: ${actionOptions.call.amount} to call`;
    }
  }

  return <>It's {textPart1}{textPart2}</>;
}

function extractPosition(game: Game, player: string): React.ReactNode {
  const list = [
    game.buttonPlayer === player && 'btn',
    findPlayer(game.allPlayers, game.buttonPlayer, 'bb') === player && 'BB',
    findPlayer(game.allPlayers, game.buttonPlayer, 'sb') === player && 'SB',
    findPlayer(game.allPlayers, game.buttonPlayer, 'utg') === player && 'utg',
  ].filter(Boolean) as string[];

  if (list.length === 0)
    return '';

  if (game.allPlayers.length === 2 && list[0] === 'btn')
    return 'D/SB';

  return <span className="position">{list[0]}</span>;
}


function extractDisplayStateFromGame(game: Game, player: string): 'folded' | 'sit-out' | 'all-in' | 'checked' | 'raised' | 'called' | 'won' | 'split' | 'lost' | 'default' {
  if (game.currentRound === 'showdown') {
    if (game.isAllPotsSettled) {
      const result = [];
      for (const i of R.range(0, game.pots.length)) {
        if (game.potResult![i].includes(player))
          if (game.potResult![i].length === 1)
            result.push('won');
          else
            result.push('split');
        else
          result.push('lost');
      }
      if (result.includes('won'))
        return 'won';
      if (result.includes('split'))
        return 'split';
      return 'lost';
    }

    if (game.foldedPlayers.includes(player))
      return 'lost';

    if (!game.allPlayers.includes(player))
      return 'sit-out';

    if (game.allInPlayers.includes(player))
      return 'all-in';

    return 'called';
  }



  if (game.foldedPlayers.includes(player))
    return 'folded';

  if (game.allInPlayers.includes(player))
    return 'all-in';

  if (!game.allPlayers.includes(player))
    return 'sit-out';

  if (game.actQueue.includes(player))
    return 'default';

  const playerBet = game.currentBets[player] ?? 0;
  const maxBetExceptPlayer = Math.max(0, ...Object.entries(game.currentBets).filter(([p, _]) => p !== player).map(([_, x]) => x));
  if (playerBet === 0)
    return 'checked';

  if (playerBet === maxBetExceptPlayer)
    return 'called';

  if (playerBet > maxBetExceptPlayer)
    // return 'raised'; TODO: Currently there is no way to determine if the player raised
    return 'called';

  throw new Error('?');
}
