import { FC } from 'react';
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend';
import HomePage from './react/HomePage';
import MultiScreenPage from './react/MultiScreenPage';
import JoinPage from './react/JoinPage';

const App: FC = () => {
  const url = document.URL;
  const { pathname } = new URL(url);

  let ComponentToRender;

  if (pathname === '/') {
    ComponentToRender = HomePage;
  } else if (pathname.match(/\/\d+/)) {
    ComponentToRender = JoinPage;
  } else if (pathname === '/demo') {
    ComponentToRender = MultiScreenPage;
  } else {
    ComponentToRender = HomePage;
  }

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <ComponentToRender />
    </DndProvider>
  );
}


export default App;
