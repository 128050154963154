import { AssertionFailed } from "./errors";

/*
Assert.never('msg');
Assert(true, 'msg');
Assert.defined(value, 'msg');
Assert.expect(v1).eq(v2, 'message');
*/

const throwError = (message: string) => {
  // debugger;
  throw new AssertionFailed(message);
  // console.error(message);
}

const Assert = function (value: boolean, msg?: string) {
  if (!value) {
    throwError(msg ?? 'Expect ' + value + ' to be truthy');
  }
}

Assert.never = (msg?: string) => {
  throwError(msg ?? 'This should never be executed');
}

Assert.expect = <T>(actualValue: T) => {
  return {
    'beEmpty'(msg?: string) {
      if (Array.isArray(actualValue)) {
        if (!(actualValue.length === 0)) throwError(msg ?? `Expect array to be empty, got ${actualValue}`);
      } else if (typeof actualValue === 'string') {
        if (!(actualValue.length === 0)) throwError(msg ?? `Expect string to be empty, got ${actualValue}`);
      } else if (typeof actualValue === 'object' && actualValue !== null) {
        if (!(Object.keys(actualValue).length === 0)) throwError(msg ?? `Expect object to be empty, got ${actualValue}`);
      } else {
        throwError(`Do not know what is empty for ${actualValue}`);
      }
    },
    'beNonEmpty'(msg?: string) {
      if (Array.isArray(actualValue)) {
        if (actualValue.length === 0) throwError(msg ?? `Expect array to be non-empty, got ${actualValue}`);
      } else if (typeof actualValue === 'string') {
        if (actualValue.length === 0) throwError(msg ?? `Expect string to be non-empty, got ${actualValue}`);
      } else if (typeof actualValue === 'object' && actualValue !== null) {
        if (Object.keys(actualValue).length === 0) throwError(msg ?? `Expect object to be non-empty, got ${actualValue}`);
      } else {
        throwError(`Do not know what is empty for ${actualValue}`);
      }
    },
    'beTruthy'(msg?: string) {
      if (!actualValue) throwError(msg ?? `Expect ${msg} to be truthy`);
    },
    'beFalsy'(msg?: string) {
      if (actualValue) throwError(msg ?? `Expect ${msg} to be falsy`);
    },
    'eq'(expectedValue: T, msg?: string) {
      if (!(actualValue === expectedValue)) throwError(msg ?? `Expect ${actualValue} === ${expectedValue}`);
    },
    'ne'(expectedValue: T, msg?: string) {
      if (!(actualValue !== expectedValue)) throwError(msg ?? `Expect ${actualValue} !== ${expectedValue}`);
    },
    'gt'(expectedValue: T, msg?: string) {
      if (!(actualValue > expectedValue)) throwError(msg ?? `Expect ${actualValue} > ${expectedValue}`);
    },
    'ge'(expectedValue: T, msg?: string) {
      if (!(actualValue >= expectedValue)) throwError(msg ?? `Expect ${actualValue} >= ${expectedValue}`);
    },
    'lt'(expectedValue: T, msg?: string) {
      if (!(actualValue < expectedValue)) throwError(msg ?? `Expect ${actualValue} < ${expectedValue}`);
    },
    'le'(expectedValue: T, msg?: string) {
      if (!(actualValue <= expectedValue)) throwError(msg ?? `Expect ${actualValue} <= ${expectedValue}`);
    },
  }
}

export default Assert;
