import React from 'react';

import Animation from '@mui/icons-material/Animation';
import BlurCircular from '@mui/icons-material/BlurCircular';
import Toll from '@mui/icons-material/Toll';
import TripOrigin from '@mui/icons-material/TripOrigin';
import Workspaces from '@mui/icons-material/Workspaces';
import Deblur from '@mui/icons-material/Deblur';
import FiberSmartRecord from '@mui/icons-material/FiberSmartRecord';
import Adjust from '@mui/icons-material/Adjust';
import Circle from '@mui/icons-material/Circle';
import EggAlt from '@mui/icons-material/EggAlt';
import Stream from '@mui/icons-material/Stream';
import Texture from '@mui/icons-material/Texture';
import SupervisedUserCircleOutlined from '@mui/icons-material/SupervisedUserCircleOutlined';
import AllOutOutlined from '@mui/icons-material/AllOutOutlined';


const icons = [
  Animation,
  BlurCircular,
  Toll,
  TripOrigin,
  Workspaces,
  FiberSmartRecord,
  Adjust,
  Circle,
  Deblur,
  EggAlt,
  Stream,
  Texture,
  SupervisedUserCircleOutlined,
  AllOutOutlined,
];

interface Props {
  amount: number;
  vertical?: boolean;
}

export default function ChipAmount({ amount, vertical }: Props) {
  const [index, setIndex] = React.useState(2);
  const Icon = icons[index];

  return (
    <div className={`inline-flex ${vertical && 'flex-col'} w-fit items-center`}>
      <div className="scale-90" onClick={() => setIndex(index + 1 >= icons.length ? 0 : index + 1)}><Icon /></div>
      <div className="amount">{String(amount)}</div>
    </div>
  )
}
