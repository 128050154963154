import React from 'react';
import * as R from 'ramda';
import { QrCode2 } from '@mui/icons-material';

import { QrCodeSvg } from '../../lib/QRCode';
import { TableId } from '../../models/types';
import { Table } from '../../models/poker';
import { DraggableList, GenericInput, NumericInput, Row, RowButton } from '../components';

interface Props {
  isDisconnected: boolean;
  disconnect: () => void;
  table: Table;
  tableId: TableId;
  onUpdate: (newTable: Table) => void;
  playerId?: string;
  setPlayerId: (playerId: string) => void;
}

export default function SetupGameDisplay(props: Props) {
  const { table, onUpdate } = props;
  const [tableInputs, setTableInputs] = React.useState<Record<'buyIn' | string, number>>({});
  const [playerInputs, setPlayerInputs] = React.useState<Record<'bb' | 'sb' | 'defaultBuyIn' | string, number>>({});
  const [selectedPlayer, setSelectedPlayer] = React.useState<string | null>(null);
  const [showQRCode, toggleShowQRCode] = React.useState(0);

  const [nameInput, setNameInput] = React.useState('');

  const DOMAIN = `${window.location.protocol}//${window.location.host}`;

  const startNewGame = () => {
    table.currentGame = table.initializeNewGame();
    onUpdate(table);
  }

  const joinTable = () => {
    const trimmedNameInput = nameInput.trim();
    if (trimmedNameInput.length === 0)
      alert('Name cannot be empty');
    else
      props.setPlayerId(trimmedNameInput);
  }

  React.useEffect(() => {
    if (props.playerId)
      setNameInput(props.playerId);
  }, [props.playerId]);

  const playerIdNeeded = props.playerId === '';
  const playerIdGivenButNotInTable = props.playerId !== '' && !table.players.includes(props.playerId!);

  const shouldBounce = (() => {
    if (playerIdNeeded || playerIdGivenButNotInTable)
      return false;
    if (table.players.length > 3)
      return false;
    return true;
  })();

  return (
    <div className={`primary-game-display pgd-rainbow ${props.isDisconnected && 'container-disconnected'} p-5`}>

      <div className="flex flex-col items-center">
        <div className="flex items-center gap-x-2" onClick={() => toggleShowQRCode(v => v > 0 ? 0 : 1)}>
          <span>
            join at &nbsp;
            <span className="join-table-link">{DOMAIN.replace(/https?:\/\//, '') + '/' + props.tableId}</span>
          </span>
          <span className={"drop-shadow " + (shouldBounce ? 'animate-bounce -mb-1' : '')}>
            <QrCode2 />
          </span>
        </div>
      </div>

      {
        ((playerIdNeeded || playerIdGivenButNotInTable) &&
          <div className="bg-white dark:bg-black p-2 my-3 border-red-500 border-2 animate-border-player-id-needed">
            to continue...
            <GenericInput className="player" label="enter your name" value={nameInput} onChange={setNameInput} />
            <Row>
              <span />
              <RowButton className="bg-green-200 mt-2" label="join table" onClick={joinTable} />
            </Row>
          </div>
        )
      }

      <div className="flex flex-col items-center">
        <div data-width={300 + 'px'} className={`qrcode _visible-${showQRCode > 0 ? 'yes' : 'no'} rotate-${((showQRCode - 1) % 4) * 90}`} onClick={() => toggleShowQRCode(v => v + 1)}>
          <div className="w-full h-full">
            <QrCodeSvg text={DOMAIN + '/' + props.tableId} fgColor='var(--text)' bgColor='transparent' />
          </div>
        </div>

        {
          table.players.length === 0 ?
            <div className="mb-5 mt-3">this table is currently empty...</div>
            :
            <div className="mt-3">drag to reorder, select to edit/remove</div>
        }
        <DraggableList
          chipMap={table.tableStacks}
          initialCards={Array.from(table.players)}
          onSelectedPlayerUpdate={setSelectedPlayer}
          selectedPlayer={selectedPlayer}
          onChange={(newPlayers) => {
            const copy = table.copy();
            copy.players = newPlayers;
            onUpdate(copy);
          }}
        />
      </div>

      {selectedPlayer && (
        <div className="border-2 bg-white dark:bg-black p-2 my-3">
          player settings - {selectedPlayer}
          <NumericInput label="buy-in" value={playerInputs.buyIn ?? props.table.tableStacks[selectedPlayer]} onChange={(v) => setPlayerInputs(old => ({ ...old, buyIn: v }))} />
          <Row>
            <RowButton className="red" label="remove" onClick={() => {
              table.players = table.players.filter(p => p !== selectedPlayer);
              onUpdate(table);
              setSelectedPlayer(null);
              setPlayerInputs({});
            }} />
            <div className="flex-1" />
            {
              R.isEmpty(playerInputs) || <>
                <RowButton label="discard" onClick={() => { console.warn(444); setPlayerInputs({}) }} />
                <RowButton label="save" onClick={() => {
                  if (playerInputs.buyIn) table.tableStacks[selectedPlayer!] = playerInputs.buyIn;
                  onUpdate(table);
                  setPlayerInputs({});
                }} />
              </>
            }
          </Row>
        </div>
      )}

      <div className="border-2 bg-white dark:bg-black p-2 my-3">
        table settings
        <NumericInput label="small blind" value={tableInputs.sb ?? props.table.settings.smallBlindAmount} onChange={(v) => setTableInputs(old => ({ ...old, sb: v }))} />
        <NumericInput label="big blind" value={tableInputs.bb ?? props.table.settings.bigBlindAmount} onChange={(v) => setTableInputs(old => ({ ...old, bb: v }))} />
        <NumericInput label="new player gets" value={tableInputs.defaultBuyIn ?? props.table.settings.defaultStartingAmount} onChange={(v) => setTableInputs(old => ({ ...old, defaultBuyIn: v }))} />
        <Row>
          <span></span>
          {R.isEmpty(tableInputs) || <>
            <RowButton label="discard" onClick={() => () => { setTableInputs({}) }} />
            <RowButton label="save" onClick={() => {
              if (tableInputs.bb) table.settings.bigBlindAmount = tableInputs.bb;
              if (tableInputs.sb) table.settings.smallBlindAmount = tableInputs.sb;
              if (tableInputs.defaultBuyIn) table.settings.defaultStartingAmount = tableInputs.defaultBuyIn;
              onUpdate(table);
              setTableInputs({});
            }} />
          </>
          }
        </Row>
      </div>

      <Row>
        <RowButton className="bg-white dark:bg-black" label="exit" onClick={() => props.disconnect()} />
        <span></span>
        <RowButton className={"bg-white dark:bg-black " + (table.players.length <= 1 ? 'disabled' : '')} label="start game »" onClick={() => startNewGame()} />
      </Row>
    </div>
  )
}
