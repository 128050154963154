import { FC, PropsWithChildren } from 'react';
export { default as DraggableList } from './DraggableList';
export { default as ChipAmount } from './ChipAmount';
export { default as PokerCard } from './PokerCard';

const NaNToEmptyStr = function (v: number) {
  if (isNaN(v))
    return '';
  else if (v === 0)
    return '';
  else
    return String(v);
}

export function NumericInput({ label, value, onChange }: { label: string, value: number, onChange: (newValue: number) => void }) {
  return (
    <label className="input-group">
      <span>{label}</span>
      <input className="amount" type="text" inputMode="numeric" value={NaNToEmptyStr(value)} onChange={(e) => onChange(Number(e.target.value))} />
    </label>
  )
}

export function GenericInput({ label, value, onChange, className }: { label: string, value: string, onChange: (newValue: string) => void, className?: string }) {
  return (
    <label className="input-group">
      <span>{label}</span>
      <input className={className} type="text" value={value} onChange={(e) => onChange(e.target.value)} autoComplete='off' autoCorrect="off" autoCapitalize="off" spellCheck={false} />
    </label>
  )
}

export const Row: FC<PropsWithChildren> = ({ children }) => (
  <div className="input-group gap-x-2">
    {children}
  </div>
)

export const RowButton: FC<{ label: string, className?: string, onClick?: () => void }> = ({ label, className, onClick }) => (
  <label>
    <input className={`${className} _button`} type="button" value={label} onClick={() => {
      onClick && onClick();
    }} />
  </label>
)
