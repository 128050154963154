enum Suit {
  Club = '♣',
  Spade = '♠︎',
  Heart = '♥',
  Diamond = '♦',
  // Unknown = '★' // '☁︎',
  Unknown = ' ',
}

interface Props {
  suit: Suit | null;
  rank: string | null;
  horizontal?: boolean;
}

export default function PokerCard({ suit, rank, horizontal = false }: Props) {
  return (
    <>
      {/* <div className="poker-card alt empty">
        <div className="suit">{suit}</div>
        <div className="rank">{rank}</div>
      </div>
      <div className="poker-card alt">
        <div className="suit">{suit}</div>
        <div className="rank">{rank}</div>
      </div>
      <div className="poker-card empty">
        <div className="suit">{suit}</div>
        <div className="rank">{rank}</div>
      </div> */}
      <div className={`poker-card ${suit === null && rank === null && 'empty'} ${horizontal && 'alt'}`}>
        <div className="rank">{rank || ' '}</div>
        <div className="suit">{suit || ' '}</div>
      </div>
    </>
  );
}

PokerCard.Club = Suit.Club;
PokerCard.Spade = Suit.Spade;
PokerCard.Heart = Suit.Heart;
PokerCard.Diamond = Suit.Diamond;
PokerCard.Unknown = Suit.Unknown;
