import { FC, useRef, useState, useCallback, useEffect } from 'react'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import { DragIndicator } from '@mui/icons-material';
import ChipAmount from './ChipAmount';

export interface CardProps {
  id: any
  player: string
  amount: number
  index: number
  onClick: () => void
  isSelected: boolean
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

const Card: FC<CardProps> = ({ id, player, amount, index, moveCard, isSelected, onClick }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<any, any, any>({
    accept: 'ItemTypes.CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'ItemTypes.CARD',
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const [isTouching, setIsTouching] = useState(false);
  return (
    <div
      className={`player-container ${(isDragging || isTouching) && 'dragging'} ${isSelected && 'selected'}`}
      data-handler-id={handlerId}
      onClick={() => onClick()}
    >
      <div
        className="dragging-area"
        ref={ref}
        onMouseEnter={() => setIsTouching(true)}
        onMouseLeave={() => setIsTouching(false)}
        onTouchStart={() => setIsTouching(true)}
        onTouchEnd={() => setIsTouching(false)}
      >
      </div>
      <div className="dragging-indicator">
        <DragIndicator />
      </div>
      <span className="_player">{player}</span>
      <ChipAmount amount={amount} />
    </div>
  )
}

export interface Item {
  id: number
  text: string
}

export interface ContainerState {
  cards: Item[]
}

interface Props {
  initialCards: string[];
  chipMap: Record<string, number>;
  onChange: (newCards: string[]) => void;
  onSelectedPlayerUpdate: (selected: string | null) => void;
  selectedPlayer: string | null;
}

export const DraggableList: FC<Props> = ({ initialCards, chipMap, onChange, onSelectedPlayerUpdate, selectedPlayer }) => {
  const [cards, setCards] = useState(initialCards)

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      const newCards = [...cards];
      newCards.splice(dragIndex, 1);
      newCards.splice(hoverIndex, 0, dragCard);
      onChange(newCards);
    },
    [cards, onChange],
  )

  useEffect(() => {
    setCards(initialCards);
  }, [initialCards]);

  return (
    <div className='w-full select-none'>
      {cards.map((player, index) =>
        <Card
          key={player}
          id={player}
          player={player}
          index={index}
          amount={chipMap[player] ?? 0}
          moveCard={moveCard}
          onClick={() => {
            onSelectedPlayerUpdate(selectedPlayer === player ? null : player)
          }}
          isSelected={selectedPlayer === player}
        />
      )}
    </div>
  )
}
export default DraggableList;
