import { FC } from "react";
import DisplayContainer from './display/DisplayContainer';
import ClientController from './display/ClientController';
import Inspector from "./components/Inspector";

const JoinPage: FC = () => {
  const tableId = window.location.pathname.replace('/', '');

  document.title = `Joining table ${tableId} | vchips.app`

  const showInspector = window.location.search.includes('inspect');
  return (
    <DisplayContainer>
      <>
        <ClientController
          tableId={tableId}
          onTableDNE={() => {
            window.location.replace('/#table-dne');
          }}
          useServer='remote'
        />
        {showInspector &&
          <Inspector
            tableId={tableId}
            useServer='remote'
          />
        }
      </>
    </DisplayContainer>
  )
}

export default JoinPage;
