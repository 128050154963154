import Assert from "./assert";
import { findPlayer, generateQueueForRound } from "./helpers";
import { ChipAmount, PlayerId, PlayerChipMap, Pot, GameRoundName, PlayerAction, PlayerActionOptions } from "./types";
import * as R from 'ramda';

export interface GameSettings {
  smallBlindAmount: ChipAmount;
  bigBlindAmount: ChipAmount;
  defaultStartingAmount: ChipAmount;
}

export interface TableInterface {
  players: PlayerId[];
  tableStacks: PlayerChipMap;
  previousGames: GameInterface[];
  currentGame: Game | null;
  settings: GameSettings;
}

export interface GameInterface {
  allPlayers: PlayerId[];
  buttonPlayer: PlayerId;
  gameStacks: PlayerChipMap;
  pots: Pot[];
  currentRound: GameRoundName;
  currentBets: PlayerChipMap;
  allInPlayers: PlayerId[];
  foldedPlayers: PlayerId[];
  settledQueue: PlayerId[];
  actQueue: PlayerId[];
  potResult: PlayerId[][] | null;
  settings: GameSettings;
}

export class Table implements TableInterface {
  buttonPlayer?: PlayerId;
  players!: string[];
  tableStacks!: PlayerChipMap;
  previousGames!: GameInterface[];
  currentGame: Game | null = null;
  settings!: GameSettings;

  static getEmptyTable() {
    return new Table({
      players: [],
      previousGames: [],
      tableStacks: {},
      currentGame: null,
      settings: {
        smallBlindAmount: 5,
        bigBlindAmount: 10,
        defaultStartingAmount: 1000,
      },
    });
  }

  fromJSON(s: string) {
    Object.assign(this, JSON.parse(s));
    if (this.currentGame)
      this.currentGame = new Game(this.currentGame);
  };
  toJSON() { return this; }
  copy() { return new Table(JSON.parse(JSON.stringify(this))); }

  constructor(obj: TableInterface) {
    Object.assign(this, obj);
    if (this.currentGame)
      this.currentGame = new Game(this.currentGame);
  }

  sanityCheck() {
    Assert.expect(this.settings).beTruthy();
    Assert.expect(this.tableStacks).beTruthy();
    Assert.expect(this.players).beTruthy();
  }

  initializeNewGame() {
    if (this.players.length <= 1)
      throw new Error('Cannot start game with 1 or less people');
    this.buttonPlayer ??= this.players[0];

    // TODO: Remove players with 0 chips

    const sb = findPlayer(this.players, this.buttonPlayer, 'sb');
    const bb = findPlayer(this.players, this.buttonPlayer, 'bb');
    const gameStacks = Object.assign({}, this.tableStacks);
    // TODO: Check if they have that amount of chips
    gameStacks[sb] -= this.settings.smallBlindAmount;
    gameStacks[bb] -= this.settings.bigBlindAmount;

    return new Game({
      allPlayers: this.players,
      buttonPlayer: this.buttonPlayer,
      gameStacks,
      pots: [],
      currentRound: 'pre-flop',
      currentBets: { [sb]: this.settings.smallBlindAmount, [bb]: this.settings.bigBlindAmount },
      allInPlayers: [],
      foldedPlayers: [],
      settledQueue: [],
      actQueue: generateQueueForRound(this.players, this.players, this.buttonPlayer, 'pre-flop'),
      potResult: null,
      settings: this.settings,
    });
  }

  addNewPlayer(playerId: PlayerId) {
    if (!this.players.includes(playerId))
      this.players.push(playerId);
    this.tableStacks[playerId] ??= this.settings.defaultStartingAmount;
  }

  addPreviousGame(game: Game) {
    // this.previousGames.push(game);
    const winnerChipMap = game.getWinnerChipMap();

    this.tableStacks = mergePlayerChipMap(game.gameStacks, winnerChipMap);

    this.buttonPlayer = this.players[this.players.indexOf(game.buttonPlayer) + 1] ?? this.players[0];

    function mergePlayerChipMap(a: PlayerChipMap, b: PlayerChipMap): PlayerChipMap {
      const c = Object.assign({}, a);
      for (const key in b) c[key] += b[key];
      return c;
    }
  }

  addCurrentGameToPreviousGames() {
    if (this.currentGame === null) { throw new Error('There must be a current game') };
    this.addPreviousGame(this.currentGame);
    this.currentGame = null;
  }
}

export class Game implements GameInterface {
  allPlayers!: PlayerId[];
  buttonPlayer!: PlayerId;
  gameStacks!: PlayerChipMap;
  pots!: Pot[];
  currentRound!: GameRoundName;
  currentBets!: PlayerChipMap;
  allInPlayers!: PlayerId[];
  foldedPlayers!: PlayerId[];
  settledQueue!: PlayerId[];
  actQueue!: PlayerId[];
  potResult: PlayerId[][] | null = null;
  settings!: GameSettings;

  fromJSON(s: string) { Object.assign(this, JSON.parse(s)); };
  toJSON() { return this; }
  copy() { return new Game(JSON.parse(JSON.stringify(this))); }

  constructor(obj: GameInterface) {
    Object.assign(this, obj);
    this.sanityCheck();
  }

  sanityCheck() {
    const assertUnique = (array: any) => {
      Assert(Array.isArray(array), 'Expected to be array');
      Assert.expect(new Set(array).size).eq(array.length, 'should be unique');
    }
    // allPlayers is unique
    assertUnique(this.allPlayers);
    // allPlayers.length >= 2
    Assert.expect(this.allPlayers.length).ge(2, 'allPlayers size should be >= 2');
    // allPlayers.includes(buttonPlayer)
    Assert(this.allPlayers.includes(this.buttonPlayer), 'buttonPlayer should be in allPlayers');
    // allInPlayers, foldedPlayers, settledQueue, actQueue are unique
    const union = [...this.allInPlayers, ...this.foldedPlayers, ...this.settledQueue, ...this.actQueue];
    assertUnique(union);
    // union of all of them === allPlayer
    Assert(union.length === this.allPlayers.length);
    this.allPlayers.forEach((p) => Assert(union.includes(p)));

    // gameStack >= 0
    Object.entries(this.gameStacks).forEach(([key, value]) => {
      Assert(this.allPlayers.includes(key), `${key} is not a valid player`);
      Assert(value >= 0, 'gameStack must >= 0');
      if (value === 0) {
        Assert(this.allInPlayers.includes(key), `${key} has 0 gameStack but is not in allInPlayers`);
      }
    });
    // in allInPlayers <=> gameStack == 0
    this.allInPlayers.forEach(p => this.gameStacks[p] === 0);
    // pots:
    this.pots.forEach(pot => {
      // amount >= 0
      Assert.expect(pot.amount).ge(0);
      // players.each(p => allPlayers.includes(p))
      pot.players.forEach(p => Assert(this.allPlayers.includes(p)));
    })

    if (this.currentRound === 'showdown') {
      Assert.expect(this.potResult).beTruthy();
    } else {
      Assert.expect(this.potResult).beFalsy();
    }
  }

  get currentPlayer(): PlayerId | null {
    if (this.actQueue.length === 0) return null;
    if (this.currentRound === 'showdown') return null;
    return this.actQueue[0];
  }

  get currentPlayerActionOptions(): PlayerActionOptions {
    const playerToAct = this.currentPlayer;
    if (playerToAct === null) return {
      fold: false,
      check: false,
      call: null,
      raise: null,
    };

    const maxBetSoFar = Math.max(...Object.values(this.currentBets), 0);
    const playerBet = this.currentBets[playerToAct] ?? 0;
    const costToCall = maxBetSoFar - playerBet;
    const playerStack = this.gameStacks[playerToAct];

    const result: PlayerActionOptions = {
      fold: true,
      check: false,
      call: null,
      raise: null,
    }

    const minimumBet = this.settings.bigBlindAmount;

    if (costToCall === 0) {
      // Check or Raise
      result.check = true;
      result.raise = { minAmount: minimumBet, maxAmount: playerStack };
    } else {
      // Call or Raise
      if (playerStack <= costToCall) {
        result.call = { amount: playerStack, isAllIn: true }; // Must all in to call
      } else {
        result.call = { amount: costToCall, isAllIn: false };

        const minRaise = costToCall;
        result.raise = { minAmount: Math.min(minRaise, playerStack), maxAmount: playerStack };
      }
    }
    return result;
  }

  act(action: PlayerAction) {
    Assert.expect(this.currentRound).ne('showdown');
    Assert.expect(this.actQueue).beNonEmpty();

    const actionOptions = this.currentPlayerActionOptions;
    const currentPlayer = this.actQueue.shift()!;
    const currentBet = this.currentBets[currentPlayer] ??= 0;
    let newCurrentBet = currentBet;
    const currentStack = this.gameStacks[currentPlayer];
    let newCurrentStack = currentStack;

    Assert.expect(actionOptions[action.type]).beTruthy();

    if (action.type === 'fold') {
      // Remove this player from all pots
      this.pots.forEach(pot => { pot.players = pot.players.filter(p => p !== currentPlayer); });
      this.foldedPlayers.push(currentPlayer);

    } else if (action.type === 'check') {
      this.settledQueue.push(currentPlayer);

    } else if (action.type === 'call') {
      newCurrentStack = currentStack - actionOptions.call!.amount;
      newCurrentBet = currentBet + actionOptions.call!.amount;
      if (actionOptions.call!.isAllIn) {
        this.allInPlayers.push(currentPlayer);
      } else {
        this.settledQueue.push(currentPlayer);
      }

    } else if (action.type === 'raise') {
      Assert.expect(action.raisingAmount).le(actionOptions[action.type]!.maxAmount);
      Assert.expect(action.raisingAmount).ge(actionOptions[action.type]!.minAmount);
      newCurrentStack = currentStack - action.raisingAmount;
      newCurrentBet = currentBet + action.raisingAmount;
      this.actQueue.push(...this.settledQueue);
      this.settledQueue = [];
      const isAllIn = actionOptions[action.type]?.maxAmount === action.raisingAmount;
      if (isAllIn) {
        this.allInPlayers.push(currentPlayer);
      } else {
        this.settledQueue.push(currentPlayer);
      }

    } else {
      Assert.never();
    }

    this.currentBets[currentPlayer] = newCurrentBet;
    this.gameStacks[currentPlayer] = newCurrentStack;

    // If there is only one player left to act, automatically act 'check' for it
    // if (this.allPlayers.length - this.allInPlayers.length - this.foldedPlayers.length === 1) {
    if (this.allPlayers.length - this.foldedPlayers.length === 1) {
      if (this.actQueue.length > 0)
        this.settledQueue.push(this.actQueue.pop()!);
    }

    if (this.actQueue.length === 0) {
      let nextRound: GameRoundName;
      const newActQueue = generateQueueForRound(this.allPlayers, this.settledQueue, this.buttonPlayer, 'post-pre-flop');
      const inGamePlayers = [...this.allInPlayers, ...this.settledQueue];
      const foldedPlayers = this.foldedPlayers;
      Assert.expect(inGamePlayers.length + foldedPlayers.length).eq(this.allPlayers.length);

      let inGameBets: Record<PlayerId, ChipAmount> = {}, foldedBets: Record<PlayerId, ChipAmount> = {};
      for (const [player, amount] of Object.entries(this.currentBets)) {
        if (amount === 0) continue;
        if (inGamePlayers.includes(player)) {
          inGameBets[player] = amount;
        } else {
          foldedBets[player] = amount;
        }
      }

      this.currentBets = {};

      const newPots: Record<string, number> = {};

      while (true) {
        const minimumBet = R.reduce<number, number>(R.min, Infinity, R.values(inGameBets));
        if (minimumBet === Infinity) break;

        const newInGameBets: typeof inGameBets = {};
        let accumulated = 0;
        for (const [player, bet] of Object.entries(inGameBets)) {
          const newBet = bet - minimumBet;
          if (newBet > 0) {
            newInGameBets[player] = newBet;
            accumulated += minimumBet;
          } else if (newBet === 0) {
            accumulated += minimumBet;
          } else {
            Assert.never('newBet should always be non-negative. player=' + player);
          }
        }

        Assert.expect(accumulated % minimumBet).eq(0);

        const newFoldedBets: typeof foldedBets = {};
        for (const [player, bet] of Object.entries(foldedBets)) {
          const newBet = Math.max(0, bet - minimumBet);
          if (newBet > 0) {
            newFoldedBets[player] = newBet;
            accumulated += minimumBet;
          } else {
            accumulated += bet;
          }
        }

        const oldBets = R.sum(R.map(R.values, [inGameBets, foldedBets]).flat());
        const newBets = R.sum(R.map(R.values, [newInGameBets, newFoldedBets]).flat());
        Assert.expect(oldBets - newBets).eq(accumulated);
        Assert.expect(accumulated).gt(0);

        const involvedPlayersStr = JSON.stringify(R.keys(inGameBets).sort());
        Assert.expect(involvedPlayersStr in newPots).beFalsy();
        newPots[involvedPlayersStr] = accumulated;

        inGameBets = newInGameBets;
        foldedBets = newFoldedBets;
      }

      for (const [involvedPlayersStr, amount] of Object.entries(newPots)) {
        const existingPotIndex = this.pots.findIndex((pot) => JSON.stringify(pot.players) === involvedPlayersStr);

        if (existingPotIndex >= 0) {
          this.pots[existingPotIndex].amount += amount;
        } else {
          this.pots.push({
            players: JSON.parse(involvedPlayersStr),
            amount,
          })
        }
      }

      if (newActQueue.length === 0 || newActQueue.length === 1) {
        nextRound = 'showdown';
      } else {
        this.settledQueue = [];
        this.actQueue = newActQueue;
        const gameRounds: GameRoundName[] = ["pre-flop", "flop", "turn", "river", "showdown"];
        nextRound = gameRounds[gameRounds.indexOf(this.currentRound) + 1];
        Assert(nextRound !== undefined && nextRound !== 'pre-flop');
      }

      if (nextRound === 'showdown') {
        this.potResult = this.pots.map((pot) => pot.players.length === 1 ? [pot.players[0]] : []);
      }

      this.currentRound = nextRound;
    }

    this.sanityCheck();
  }

  toString(): string {
    let result = "";
    // Pots
    if (this.pots.length === 1)
      result += `Pot: ${this.pots[0].amount}\n`;
    else {
      result += `Pots:\n`;
      for (const p of this.pots)
        result += `- ${p.amount} (${p.players.join(', ')})\n`;
    }

    // Round
    result += `Round: ${this.currentRound}\n`;
    result += `\n`;

    // Players
    for (const p of this.settledQueue) result += `${p} (${this.gameStacks[p]}): ${this.currentBets[p] ?? 0}\n`;
    result += '> ';
    for (const p of this.actQueue) result += `${p} (${this.gameStacks[p]}): ${this.currentBets[p] ?? 0}\n`;

    return result;
  }

  isPotSettled(index: number) {
    // TODO: Check if winner makes sense across different pots (not sure if this is needed)
    return this.potResult![index].length > 0;
  }

  get isAllPotsSettled() {
    for (let i = 0; i < this.pots.length; i++) {
      if (!this.isPotSettled(i))
        return false;
    }
    return true;
  }

  getWinnerChipMap(): PlayerChipMap {
    Assert.expect(this.isAllPotsSettled).beTruthy();
    const winnerChipMap: PlayerChipMap = {};

    this.potResult!.forEach((winners, index) => {
      const originalPot = this.pots[index];
      Assert.expect(winners).beNonEmpty('There must be winners');
      // TODO: Split to integer number only
      const winningAmount = originalPot.amount / winners.length;
      for (const winner of winners) {
        winnerChipMap[winner] ??= 0;
        winnerChipMap[winner] += winningAmount;
      }
    });

    return winnerChipMap;
  }
}
