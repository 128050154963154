import { PlayerId } from "./types";

export function generateQueueForRound(allPlayers: PlayerId[], inGamePlayers: PlayerId[], buttonPlayer: PlayerId, order: 'pre-flop' | 'post-pre-flop') {
  // Here is how the algorithm is implemented in the way that minimizes cognitive overload:
  // Given
  //     allPlayers  [  1  2  3  4  5  6  7  8  ]
  // inGamePlayers   [     2        5  6     8  ]

  // First: generate allPlayers * 3 (to account for all cases where overflow is possible), so
  //         result  [  1  2  3  4  5  6  7  8  1  2  3  4  5  6  7  8  1  2  3  4  5  6  7  8  ]

  // Find the player we want to start with.
  //         result  [                 6  7  8  1  2  3  4  5  6  7  8  1  2  3  4  5  6  7  8  ]

  // Filter so that we only include players that are in inGamePlayers
  //         result  [                 6     8     2        5  6     8     2        5  6     8  ]

  // Take the first x elements, where x = inGamePlayers.length
  //         result                 [  6     8     2        5  ]  --  Done!

  let result = [...allPlayers, ...allPlayers, ...allPlayers];
  const buttonPlayerPosition = allPlayers.indexOf(buttonPlayer);
  if (buttonPlayerPosition === -1) throw new Error('Button player is not found in player list');

  let targetPlayerIndex: number;
  if (order === 'pre-flop')
    targetPlayerIndex = buttonPlayerPosition + 3;
  else if (order === 'post-pre-flop')
    targetPlayerIndex = buttonPlayerPosition + 1;
  else
    throw new Error('Unexpected order: ' + order);

  result.splice(0, targetPlayerIndex);
  result = result.filter(p => inGamePlayers.includes(p));
  return result.slice(0, inGamePlayers.length);
}


export function findPlayer(players: PlayerId[], button: PlayerId, type: 'utg' | 'sb' | 'bb'): PlayerId {
  const buttonIndex = players.indexOf(button);

  if (buttonIndex === -1)
    throw Error('Button player is not in players: ' + { players, button });

  if (players.length === 2) {
    // https://en.wikipedia.org/wiki/Blind_(poker)
    // The one exception is when there are only two players (a "heads-up" game), when the player on the button is the small blind, and the other player is the big blind.
    if (type === 'sb' || type === 'utg') return button;
    return players.find(p => p !== button)!;
  }

  let i = 0;

  switch (type) {
    case 'sb':
      i = buttonIndex + 1;
      break;

    case 'bb':
      i = buttonIndex + 2;
      break;

    case 'utg':
      i = buttonIndex + 3;
      break;

    default:
      throw Error('Unexpected type: ' + type);
  }
  if (i >= players.length) i -= players.length;

  if (players[i] === undefined)
    throw Error('Internal Error: Unexpected undefined');

  return players[i];
}


export function objectSumValues(obj: Record<any, number>): number {
  return Object.values(obj).reduce((x, p) => x + p, 0)
}

export function generateObjectWithKeysAndValue<T>(keys: Array<string>, value: T): Record<string, T> {
  return Object.assign({}, ...keys.map(p => ({ [p]: value })));
}

export function extractKeysWithValue<K extends string | number | symbol, V>(obj: Record<K, V>, value: V): [K[], Record<K, V>] {
  const result = {} as Record<K, V>;
  const keys: K[] = [];
  for (const key in obj) {
    if (obj[key] === value) {
      keys.push(key);
    } else {
      result[key] = obj[key];
    }
  }

  return [keys, result];
}

export function arrayHaveIdenticalElements<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) return false;
  for (const elem of arr1)
    if (!arr2.includes(elem)) return false;
  for (const elem of arr2)
    if (!arr1.includes(elem)) return false;
  return true;
}
