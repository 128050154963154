import React from 'react';

import { ServerProxyFirebase, ServerProxyLocal } from '../../models/server';
import { TableId } from '../../models/types';
import { Table, TableInterface } from '../../models/poker';
import { Table as MuiTable, TableCell, TableRow, TableBody, Button, ButtonGroup } from '@mui/material';

interface Props {
  tableId: TableId,
  useServer: 'remote' | 'local',
}

interface State {
  tableHistory: Table[];
}

export default class Inspector extends React.Component<Props, State> {
  proxy: ServerProxyFirebase | ServerProxyLocal;
  state: State = {
    tableHistory: [],
  }
  constructor(props: Props) {
    super(props);
    this.proxy = props.useServer === 'remote' ? new ServerProxyFirebase() : new ServerProxyLocal();
  }

  componentDidMount() {
    try {
      this.proxy.registerHandlers({
        onConnect: this.onNewTable,
        onDisconnect: () => console.warn('json inspector disconnected'),
        onTableDNE: () => console.warn('table DNE'),
        onUpdate: this.onNewTable,
      });

      this.proxy.joinTable(this.props.tableId);
    } catch (error) {
      if (error instanceof (Error) && error.message === 'Handlers are already registered') {
      } else {
        throw error;
      }
    }
  }

  onNewTable = (newTable: Table) => {
    console.log('json inspector got new table');
    if (JSON.stringify(this.state.tableHistory[0]) !== JSON.stringify(newTable)) {
      this.setState({ tableHistory: [newTable, ...this.state.tableHistory] });
    }
  }

  setJson = (table: Table) => {
    this.proxy.updateTable(table);
  }

  copy = (table: Table) => {
    navigator.clipboard.writeText(JSON.stringify(table, null, 4));
  }

  paste = () => {
    navigator.clipboard.readText().then((text) => {
      let table;
      try {
        table = new Table(JSON.parse(text) as unknown as TableInterface);
      } catch (error) {
        console.error(error);
        return;
      }
      this.setJson(table);
    }).catch(console.error);
  }

  render() {
    const { useServer, tableId } = this.props;
    const { tableHistory } = this.state;
    return (
      <div>
        <span>
          {useServer}: table '{String(tableId)}'
          <Button size="small" variant="text" onClick={this.paste}>Paste</Button>
        </span>
        {
          tableHistory.map((table, index) =>
            <div key={index} className="border-2 m-2 p-2">
              <details>
                <summary>
                  <span className="inline-flex">
                    #{simpleHash(table)}
                    <span className="flex-1 w-10"></span>
                    <ButtonGroup size="small" variant="text">
                      <Button onClick={() => this.setJson(table)} disabled={JSON.stringify(table) === JSON.stringify(tableHistory[0])}>Revert to this</Button>
                      <Button onClick={() => this.copy(table)}>Copy</Button>
                    </ButtonGroup>
                  </span>
                  <MuiTable size="small">
                    <TableBody>
                      {pokerTableToDisplayTable(table)
                        ?.map(x =>
                          <TableRow key={String(x)}>{
                            x.map((y, i) =>
                              <TableCell sx={{ 'fontWeight': i === 0 ? 'bold' : '' }} key={String(i)}>
                                {String(y)}
                              </TableCell>)}
                          </TableRow>)}
                    </TableBody>
                  </MuiTable>
                </summary>
                <pre className="text-xs">{JSON.stringify(table, null, 2)}</pre>
              </details>
            </div>
          )
        }
      </div>
    );
  }
}

function pokerTableToDisplayTable(table: Table) {
  if (table.currentGame) {
    const game = table.currentGame;
    return [
      ['players', ...game.allPlayers.map(p => game.buttonPlayer === p ? p + ' (button)' : p)],
      [`states (${game.currentRound})`, ...game.allPlayers.map(p =>
        game.allInPlayers.includes(p) ? 'all-in' :
          game.foldedPlayers.includes(p) ? 'folded' :
            game.settledQueue.includes(p) ? 'settled' :
              '#' + String(game.actQueue.indexOf(p))
      )],
      ['currentBets', ...game.allPlayers.map(p => game.currentBets[p])],
      ['gameStacks', ...game.allPlayers.map(p => game.gameStacks[p])],
      ...game.pots.map((pot, index) =>
        [
          `pot #${index}: ${pot.amount}`, ...game.allPlayers.map(
            (p, index) =>
              (pot.players.includes(p) ? 'involved' : '')
              + ' ' +
              (
                (game.potResult?.[index] ?? []).includes(p) ? 'won' : ''
              )
          )]
      ),
    ]
  }
}

function simpleHash(obj: any) {
  return Array.from(JSON.stringify(obj)).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0).toString(36);
}
