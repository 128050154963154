import React, { FC } from "react";

import { PubSub, ServerProxyLocal } from '../models/server';
import DisplayContainer from './display/DisplayContainer';
import ClientController from './display/ClientController';
import { Table, TableInterface } from "../models/poker";
import { Button, ButtonGroup } from "@mui/material";
import * as R from 'ramda'
import { AssertionFailed } from "../models/errors";
import Inspector from "./components/Inspector";


const SIZE = { height: 400, width: 300 };

// @ts-ignore
globalThis.pubsub ??= new PubSub();


interface Props {
}

interface State {
  tables: Record<string, Table>,
  visibleTables: string[],
  sizeFactor: number,
  inspecting: string | null,
}

class DevPage extends React.Component<Props, State> {
  state: State = {
    tables: {},
    visibleTables: [],
    sizeFactor: 1,
    inspecting: null,
  }

  subscribed = new Set();

  componentDidMount() {
    const tableIds = ServerProxyLocal.getAllTableIds();
    for (const id of tableIds) {
      this.subscribeToTable(id);
    }

    document.title = 'Development Tools | vchips.app'
  }

  subscribeToTable(tableId: string) {
    if (this.subscribed.has(tableId))
      return;
    this.subscribed.add(tableId);
    const onUpdate = (table: Table) => {
      this.setState(state => ({ tables: { ...state.tables, [tableId]: table } }));
    }

    const proxy = new ServerProxyLocal();
    proxy.registerHandlers({
      onConnect: onUpdate,
      onUpdate,
      onTableDNE: () => console.warn('table DNE!!'),
      onDisconnect: () => console.warn('Disconnected'),
    });
    proxy.joinTable(tableId);
  }

  addTable(tableJson: string | null, tableId?: string) {
    let newTable = Table.getEmptyTable();
    try {
      if (tableJson !== null)
        newTable = new Table(JSON.parse(tableJson) as unknown as TableInterface);
      newTable.sanityCheck();
    } catch (error) {
      if (error instanceof AssertionFailed) {
        console.log(error);
        alert('Not a valid table JSON');
        return;
      }
    }

    tableId = tableId ?? prompt('table name?') ?? undefined;

    if (tableId) {
      setTable(tableId, newTable);
      this.subscribeToTable(tableId);
      this.setState((state) => ({
        tables: { ...state.tables, [tableId!]: newTable },
        visibleTables: [...state.visibleTables, tableId!],
      }))
    }
  }

  removeTable(tableId: string) {
    ServerProxyLocal.clearTable(tableId);
    this.setState({
      tables: R.omit([tableId], this.state.tables),
      visibleTables: this.state.visibleTables.filter(v => v !== tableId),
    })
  }

  exportAllTables() {
    copyTables(this.state.tables);
  }

  async importAllTables() {
    const tablesStr = JSON.parse(await navigator.clipboard.readText());
    for (const tableId of Object.keys(this.state.tables)) {
      this.removeTable(tableId);
    }
    for (const [tableId, table] of Object.entries(tablesStr)) {
      this.addTable(JSON.stringify(table), tableId);
    }
  }

  setInspect = (tableId: string) => {
    this.setState({ inspecting: null }, () => {
      this.setState({ inspecting: tableId });
    });
  }

  render() {
    const tableIds = R.keys(this.state.tables);
    tableIds.sort();
    return (<div>
      <Select
        items={R.mergeAll(R.map((x) => ({ [x]: this.state.visibleTables.includes(x) }), tableIds))}
        onChange={(item: string, newValue: boolean) => {
          this.setState((state) => {
            if (newValue)
              return { visibleTables: [...state.visibleTables, item] }
            else
              return { visibleTables: state.visibleTables.filter(v => v !== item) }
          })
        }}
      />

      <ButtonGroup size="small" color="secondary">
        <Button onClick={() => { this.addTable(null) }}>New Empty Table</Button>
        <Button onClick={async () => { this.addTable(await navigator.clipboard.readText()) }}>Paste Table</Button>
        <Button onClick={() => this.setState({ sizeFactor: this.state.sizeFactor + 0.1 })}>Size +</Button>
        <Button onClick={() => this.setState({ sizeFactor: this.state.sizeFactor - 0.1 })}>Size -</Button>
        <Button onClick={() => this.exportAllTables()}>Export All</Button>
        <Button onClick={() => this.importAllTables()}>Replace All</Button>
      </ButtonGroup>

      <div>
        {Object.entries(this.state.tables).map(([tableId, table]) =>
          !this.state.visibleTables.includes(tableId) ?
            null :
            <div key={tableId} className="flex flex-wrap border border-black dark:border-white">
              <div className="flex flex-col py-2 items-center">
                <div>tableId: {tableId}</div>
                <Button onClick={() => addNewPlayerToTable(tableId)}>New Player</Button>
                <Button onClick={() => this.setInspect(tableId)}>Inspect</Button>
                <Button onClick={() => copyTable(table)}>Copy</Button>
                <Button onClick={() => this.removeTable(tableId)}>Remove</Button>
              </div>
              {
                table.players.map(player =>
                  <DisplayContainer key={player} width={SIZE.width * this.state.sizeFactor} height={SIZE.height * this.state.sizeFactor}>
                    <ClientController
                      playerId={player}
                      tableId={tableId}
                      onTableDNE={() => console.warn('Table does not exist')}
                      useServer='local'
                    />
                  </DisplayContainer>
                )
              }
            </div>
        )}
      </div>

      {
        this.state.inspecting &&
        <Inspector useServer="local" tableId={this.state.inspecting} />
      }

    </div>)
  }
}

function addNewPlayerToTable(tableId: string) {
  const proxy = new ServerProxyLocal();
  const onConnect = (table: Table) => {
    table.addNewPlayer(Math.random().toString().substring(2, 6));
    proxy.updateTable(table);
    proxy.exitTable();
  };
  proxy.registerHandlers({
    onConnect,
    onUpdate: () => { },
    onTableDNE: () => console.warn('table DNE'),
    onDisconnect: () => { },
  })
  proxy.joinTable(tableId);
}

function setTable(tableId: string, table: Table) {
  ServerProxyLocal.clearTable(tableId);
  const proxy = new ServerProxyLocal();
  proxy.createTable(tableId);
  proxy.registerHandlers({
    onConnect: () => {
      proxy.updateTable(table);
      proxy.exitTable();
    },
    onUpdate: () => { },
    onTableDNE: () => console.warn('table DNE'),
    onDisconnect: () => { },
  })
  proxy.joinTable(tableId);

}

const Select: FC<{
  items: Record<string, boolean>,
  onChange: (item: string, newValue: boolean) => void,
}> = ({ items, onChange }) => {
  return (
    <ButtonGroup size="small">
      {
        Object.entries(items).map(([name, selected]) => (
          <Button
            key={name}
            variant={selected ? 'contained' : 'outlined'}
            onClick={() => onChange(name, !selected)}
          >{name}</Button>
        ))
      }
    </ButtonGroup>
  )
}

function copyTable(table: Table) {
  navigator.clipboard.writeText(JSON.stringify(table, null, 4));
}

function copyTables(tables: Record<string, Table>) {
  navigator.clipboard.writeText(JSON.stringify(tables, null, 4));
}

export default DevPage;
